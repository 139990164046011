<template>
  <base-form-item
      :field="field"
      :schemaVersion="schemaVersion"
  >
    <el-select
        :autocomplete="meta && meta.autocomplete ? meta.autocomplete : 'off'"
        :class="checkinputRequired(field.dbName) ? 'validate-error' : ''"
        :clearable="meta && meta.clearable ? meta.clearable : false"
        :collapse-tags="meta && meta.collapseTags ? meta.collapseTags : false"
        :disabled="meta && meta.disabled ? meta.disabled : false"
        :filterable="typeof meta.filterable === 'undefined' ? true : meta && !!meta.filterable"
        :multiple-limit="meta && meta.multipleLimit ? meta.multipleLimit : 0"
        :name="field.dbName"
        :no-data-text="meta && meta.noDataText ? meta.noDataText : 'No data'"
        :no-match-text="meta && meta.noMatchText ? meta.noMatchText : 'No matching data'"
        :placeholder="meta && meta.placeholder ? meta.placeholder : 'Select'"
        :remote="isRemote"
        :remote-method="isRemote ? remoteMethod : () => {}"
        :reserve-keyword="meta && meta.reserveKeyword ? meta.reserveKeyword : false"
        :size="meta && meta.size ? meta.size : ''"
        :value="value"
        @change="onChange"
        multiple
        v-model="value"
    >
      <template slot="empty">
        <div class="el-select-dropdown__empty">
          {{
            showRemoteTip ? $t('system.enter_3_or_more_characters') : loading ? $t('system.loading') : $t('system.no_data')
          }}
        </div>
      </template>
      <el-option
          :key="item.id"
          :label="field.front_properties.translate ? $t(`system.${item.name.toLowerCase()}`) : item.name"
          :value="item.id"
          v-for="item in dictionaries && dictionaries[dictionaryName] ? dictionaries[dictionaryName] : []"
      >
      </el-option>
    </el-select>
    <small
        class="help-block"
        v-if="backend_errors[field.dbName] && !checkinputRequired(field.dbName)"
    >
      <span v-html="validationTranslate(backend_errors[field.dbName][0])"></span>
    </small>
  </base-form-item>
</template>

<script>
import abstractForm from '../../mixin/index';
import functions from '../../mixin/functions';
import BaseFormItem from '../../UI/FormItem.vue';

export default {
  mixins: [abstractForm, functions],
  name: 'CreateOrUpdate',
  components: {BaseFormItem},
  data() {
    return {
      searchPhrase: '',
      loading: false,
    };
  },
  computed: {
    isRemote() {
      return !!(this.field && this.field.dictionary && this.field.dictionary.is_remote);
    },
    showRemoteTip() {
      return !!(this.isRemote && this.searchPhrase.length < 3 && !this.value);
    },
  },
  created() {
    if (this.value) {
      this.value = parseInt(this.value);
    } else {
      this.value = [];
    }
    // if (this.meta && this.meta.multiple) {
    //   if (!this.value) {
    //     this.value = []
    //   }
    // }
  },
  mounted() {
    if (this.mode === 'create' && !this.value) {
      const defaultValue = Array.isArray(this.dictionaries[this.dictionaryName])
                           ? this.dictionaries[this.dictionaryName].find(item => item.is_default === 1)
                           : null;
      if (defaultValue) {
        this.value = defaultValue.id;
      }
    }
  },
  methods: {
    onChange(val) {
      if (this.meta && this.meta.onChange) {
        this[this.meta.onChange](val);
      }
    },
    async remoteMethod(val) {
      this.searchPhrase = val;
      if (val.length > 2) {
        this.loading = true;
        const {data} = await this.fetchDictionary(
            `aberit_module_generator/get_dictionary_data?dictionary_name=${this.dictionaryName}`,
            {search: val},
        );
        if (data) {
          this.dictionaries[this.dictionaryName] = data;
        }
        this.loading = false;
      } else {
        this.dictionaries[this.dictionaryName] = [];
      }
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
>
.el-select--matrix {
  .el-select-dropdown__item {
    height: auto !important;
  }
}

.el-select--matrixOptionNoWrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 330px;
  padding-right: 40px;
}
</style>
